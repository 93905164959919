/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    wechat: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11.176 14.429c-2.665 0-4.826-1.8-4.826-4.018 0-2.22 2.159-4.02 4.824-4.02S16 8.191 16 10.411c0 1.21-.65 2.301-1.666 3.036a.32.32 0 00-.12.366l.218.81a.6.6 0 01.029.117.166.166 0 01-.162.162.2.2 0 01-.092-.03l-1.057-.61a.5.5 0 00-.256-.074.5.5 0 00-.142.021 5.7 5.7 0 01-1.576.22M9.064 9.542a.647.647 0 10.557-1 .645.645 0 00-.646.647.6.6 0 00.09.353zm3.232.001a.646.646 0 10.546-1 .645.645 0 00-.644.644.63.63 0 00.098.356"/><path pid="1" d="M0 6.826c0 1.455.781 2.765 2.001 3.656a.385.385 0 01.143.439l-.161.6-.1.373a.5.5 0 00-.032.14.19.19 0 00.193.193q.06 0 .111-.029l1.268-.733a.6.6 0 01.308-.088q.088 0 .171.025a6.8 6.8 0 001.625.26 4.5 4.5 0 01-.177-1.251c0-2.936 2.785-5.02 5.824-5.02l.15.002C10.587 3.429 8.392 2 5.796 2 2.596 2 0 4.16 0 6.826m4.632-1.555a.77.77 0 11-1.54 0 .77.77 0 011.54 0m3.875 0a.77.77 0 11-1.54 0 .77.77 0 011.54 0"/>',
    },
});
